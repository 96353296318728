.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.unselectable {
  user-select: none;
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none;    /* For Firefox */
  -ms-user-select: none;     /* For Internet Explorer/Edge */
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

*::-webkit-scrollbar {
  height: 3px;
  width: 0.5em;
}
*::-webkit-scrollbar-track {
  border-radius: 2em;
  background-color: rgba(227, 225, 225, 0.0);
}

*::-webkit-scrollbar-track:hover {
  background-color: rgba(227, 225, 225, 0.0);
}

*::-webkit-scrollbar-track:active {
  background-color: rgba(227, 225, 225, 0.0);
}

*::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: white;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #041F75;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #041F75;
}


@keyframes heightenup {
  0%, 10% {
    transform: scale(1) rotate(0deg);
  }
  15% {
    transform: scale(1.1) rotate(-1deg);

  }
  30% {
    transform: scale(1.1) rotate(1deg);

  }
  45% {
    transform: scale(1.1) rotate(-1deg);

  }
  60% {
    transform: scale(1.1) rotate(1deg);
  }
  75% {
    transform: scale(1.1) rotate(0deg);
  }
  100% {
    transform: scale(1.1) rotate(0deg);
  }
}

.activeRoom {
     z-index: 1;
     transition: transform 0.5s ease-in-out;
    background: #282c34;;
}

.activeRoom:hover {
  animation: heightenup 1s ease-in-out forwards;
  transform: scale(1.08) rotate(0deg);
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px 1px #ff79c6;
  }
  to {
    box-shadow: 0 0 20px 10px #fb86c9;
  }
}

.glowing {
  animation: glow 5s infinite alternate;
}


.glowing-dialog {
  padding: 0px;
  border-radius: 0.5em;
  width: 40em;
  text-align: center;
  background-color: #44475a;
  animation: glow 5s infinite alternate;
}




.animate__animated.animate__flipOutX {
  --animate-duration: 10s;
}